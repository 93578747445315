import React, { useState } from 'react';
import './faqs.css';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function Faqs() {
	const [expanded, setExpanded] = React.useState('panel1');

	const handleChange = (panel) => (event, isExpanded) => {
		setExpanded(isExpanded ? panel : false);
	};
	const [expandedb, setExpandedb] = React.useState('panelb1');

	const handleChangeb = (panelb) => (event, isExpanded) => {
		setExpandedb(isExpanded ? panelb : false);
	};
	return (
		<div className='faqs'>
			<div className='accordin__faqs'>
				<div className='accordin-1'>
					<Accordion
						expanded={expanded === 'panel1'}
						className='accord'
						onChange={handleChange('panel1')}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<h3>Q. What is the Modular kitchen and how it is better than normal kitchen?</h3>
						</AccordionSummary>
						<AccordionDetails>
							<ul>
								<li>Modular kitchens or factory-made kitchens are practically manufactured part by part in the factories and are later assembled on site.</li>
								<li>
									These have gained popularity in the last decade, so much so that most of us want to have a modular kitchen unit in our homes.
								</li>
								<li>
								A Modular Kitchen can be fabricated using different finishes, colours, patterns and countertop materials.
								</li>
								<li>The fabrication of the modular kitchen is more cost-effective and affordable in comparison to carpenter kitchens.</li>
							</ul>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expanded === 'panel2'}
						className='accord'
						onChange={handleChange('panel2')}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<h3>Q. How factory finish is better than carpenter finish modular furniture?</h3>
						</AccordionSummary>
						<AccordionDetails>
							<ul>
								<li>A Modular Kitchen can be fabricated using different finishes, colours, patterns and countertop materials.</li>
								<li>
								The fabrication of the modular kitchen is more cost-effective and affordable in comparison to carpenter kitchens.
								</li>
								
							</ul>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expanded === 'panel3'}
						className='accord'
						onChange={handleChange('panel3')}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<h3>Q. What are the types of Kitchens?</h3>
						</AccordionSummary>
						<AccordionDetails>
							<ul>
								<li>Straight Kitchen , Parallel Kitchen,L Shaped Kitchen, U Shaped Kitchen and Island Kitchen</li>
								
							</ul>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expanded === 'panel4'}
						className='accord'
						onChange={handleChange('panel4')}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<h3>Q. What all furniture is included in Modular Furniture?</h3>
						</AccordionSummary>
						<AccordionDetails>
							<ul>
								<li>Modular Kitchen, Modular Wardrobe, TV Panel , Storage Units, Shoe racks.</li>
								
							</ul>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expanded === 'panel5'}
						className='accord'
						onChange={handleChange('panel5')}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<h3>Q. What is the best material for a modular kitchen?</h3>
						</AccordionSummary>
						<AccordionDetails>
							<ul>
								<li>Cabinate Finish – HDMR, BWP Plywood, WPC (for Top and Sink Units)</li>
								<li>
								Shutter Finish - Laminate, Acrylic, Poly High Gloss , Venner, PU
								</li>
								
							</ul>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expanded === 'panel6'}
						className='accord'
						onChange={handleChange('panel6')}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<h3>
								Q. What are the units of a modular kitchen?
							</h3>
						</AccordionSummary>
						<AccordionDetails>
							<ul>
								<li>A kitchen is never complete without its essentials. Modern accessories are an integral part of the kitchen and help keep the area looking tidy and organised.</li>
								<li>
								Functional designs in terms of trays, drawers, holders, shelves, etc facilitate efficient space management
and Cutlery cup &amp; saucer thali tray designs: Stainless Steel Modular Kitchen tray designs are specially
engineered to help organise your crockery efficiently, and keep your kitchen looking tidy.
								</li>
								
							</ul>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expanded === 'panel7'}
						className='accord'
						onChange={handleChange('panel7')}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<h3>Q. How do I place the order of modular kitchen to Modpro?</h3>
						</AccordionSummary>
						<AccordionDetails>
							<ul>
								<li>The first step to buying a modular kitchen is to have a complete understanding of the available space.
Remember the kitchen triangle concept to consider movement between the stove, sink and refrigerator
for a smooth flow.It is important to plan your electrical fixtures smartly, depending on the appliances
you will need.</li>
								
							</ul>
						</AccordionDetails>
					</Accordion>
				</div>
				<div className='accordin-2'>
					<Accordion
						expanded={expandedb === 'panelb1'}
						className='accord'
						onChange={handleChangeb('panelb1')}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<h3>
								Q. How do I assure my client on quality/factory finish of kitchen?
							</h3>
						</AccordionSummary>
						<AccordionDetails>
							<ul>
								<li>
								Quality Check is followed by Modpro , starting from Site Validation to Installation.
								</li>
								
								
							</ul>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expandedb === 'panelb2'}
						className='accord'
						onChange={handleChangeb('panelb2')}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<h3>Q. Do you charge a design fee and quotation sharing fees?</h3>
						</AccordionSummary>
						<AccordionDetails>
							<ul>
								<li>No, design and quotation sharing is free of cost.</li>
								
							</ul>
						</AccordionDetails>
					</Accordion>
					<Accordion
						expanded={expandedb === 'panelb3'}
						className='accord'
						onChange={handleChangeb('panelb3')}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />}>
							<h3>Q. What appliances does a modular kitchen include/accommodate?</h3>
						</AccordionSummary>
						<AccordionDetails>
							<ul>
								<li>Sink</li>
								<li>
								Hob or cooktop for cooking
								</li>
								<li>
								Chimney (advisable to control kitchen smoke)
								</li>
								<li>Other appliances such as oven, microwave, dishwasher, fryer - depending on what you need.</li>
							</ul>
						</AccordionDetails>
					</Accordion>
				</div>
			</div>
		</div>
	);
}
