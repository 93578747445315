import React from 'react';
import A from './assets/Gallery/A.jpg'
import B from './assets/Gallery/B.jpeg'
import C from './assets/Gallery/C.jpg'
import D from './assets/Gallery/D.jpeg'
import E from './assets/Gallery/E.jpg'
import F from './assets/Gallery/F.jpeg'
import G from './assets/Gallery/G.jpg'
import H from './assets/Gallery/H.jpeg'
import I from './assets/Gallery/I.jpg'
import J from './assets/Gallery/J.jpeg'
import K from './assets/Gallery/K.jpg'
import L from './assets/Gallery/L.jpeg'
import M from './assets/Gallery/M.jpg'
import N from './assets/Gallery/N.jpeg'
import O from './assets/Gallery/O.jpg'
import P from './assets/Gallery/P.jpeg'
import Q from './assets/Gallery/Q.jpg'
import R from './assets/Gallery/R.jpeg'
import S from './assets/Gallery/S.jpg'
import T from './assets/Gallery/T.jpg'
import U from './assets/Gallery/U.jpg'
import V from './assets/Gallery/V.jpg'
import W from './assets/Gallery/W.jpg'
import X from './assets/Gallery/X.jpg'
import Y from './assets/Gallery/Y.jpg'
import Z from './assets/Gallery/Z.jpg'
import A1 from './assets/Gallery/A1.jpg'
import B1 from './assets/Gallery/B1.jpg'
import C1 from './assets/Gallery/C1.jpg'



const DATA = [
    {
     id:1,
     imageUrl: A,
     routeName:'/gallery/1'
    },
    {
        id:2,
        imageUrl: B,
        routeName:'/gallery/2'

    },
     {
        id:3,
        imageUrl: C,
        routeName:'/gallery/3'
    },
    {
        id:4,
        imageUrl: D,
        routeName:'/gallery/4'
    },
    {
        id:5,
        imageUrl: E,
        routeName:'/gallery/5'
    },
    {
        id:6,
        imageUrl: F,
        routeName:'/gallery/6'
    },
    {
        id:7,
        imageUrl: G,
        routeName:'/gallery/7'
    },
    {
        id:8,
        imageUrl: H,
        routeName:'/gallery/8'
    },
    {
        id:9,
        imageUrl: I,
        routeName:'/gallery/9'
    },
    {
        id:10,
        imageUrl: J,
        routeName:'/gallery/10'
    },
    {
        id:11,
        imageUrl: K,
        routeName:'/gallery/11'
    },
    {
        id:12,
        imageUrl: L,
        routeName:'/gallery/12'
    },
    {
        id:13,
        imageUrl: M,
        routeName:'/gallery/13'
    },
    {
        id:14,

        imageUrl: N,
        routeName:'/gallery/14'
    },
    {
        id:15,

        imageUrl: O,
        routeName:'/gallery/15'
    },
    {
        id:16,
        imageUrl: P,
        routeName:'/gallery/16'
    },
    {
        id:17,
        imageUrl: Q,
        routeName:'/gallery/17'
    },
    {
        id:18,
        imageUrl: R,
        routeName:'/gallery/18'
    },
    {
        id:19,
        imageUrl: S,
        routeName:'/gallery/19'
    },
    {
        id:20,
        imageUrl: T,
        routeName:'/gallery/20'
    },
    {
        id:21,
        imageUrl: U,
        routeName:'/gallery/21'
    },
    {
        id:22,
        imageUrl: V,
        routeName:'/gallery/22'
    },
    {
        id:23,
        imageUrl: W,
        routeName:'/gallery/23'
    },
    {
        id:24,
        imageUrl: X,
        routeName:'/gallery/24'
    },
    {
        id:25,
        imageUrl: Y,
        routeName:'/gallery/25'
    },
    {
        id:26,
        imageUrl: Z,
        routeName:'/gallery/26'
    },
    {
        id:27,
        imageUrl: A1,
        routeName:'/gallery/27'
    },
    {
        id:28,
        imageUrl: B1,
        routeName:'/gallery/28'
    },
    {
        id:29,
        imageUrl: C1,
        routeName:'/gallery/29'
    }
    
  ];
  
  export default DATA;