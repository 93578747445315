import React from 'react';

import Hpdivone from '../../components/Hpdiv1/hpdiv1';
import Hpdivtwo from '../../components/Hpdiv2/hpdiv2';
import Hpdivthree from '../../components/Hpdiv3/hpdiv3';
import Hpdivsix from '../../components/Hpdiv6/hpdiv6';
import Hpdivseven from '../../components/Hpdiv7/hpdiv7';
import Brands from '../../components/Brands/brands';
import HowItWorks from '../../components/HowItWorks/howitworks';
const Home =() =>{
   
    return(
        <div>
            <Hpdivone />
            <Hpdivtwo />
            <Hpdivthree />
            <HowItWorks />
            <Brands />
            <Hpdivsix />
            <Hpdivseven />
        </div>
       
    )
}
export default Home