import React , {useContext} from "react";
import { NavLink ,Link } from "react-router-dom";

import Button from '../../custom-button/custom-button.component'



import styled from "styled-components";

import "./headers.component.css";
import { NavContext } from "../../../NavProvider";

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  min-width: 70%;
  justify-content: center;
  text-transform: uppercase;
  font-weight:600;
  margin: 0;
  li {
    padding: 18px 10px;
  }
  @media (max-width: 900px) {
    flex-flow: column nowrap;
    background-color: #e85d04;
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 100%;
    z-index: 100;
    transition: transform 0.3s ease-in-out;
    li .option {
      color: white;
    }
  }
`;



const RightHeader = () => {
  const {open , toggleOpen} = useContext(NavContext) 

  return(
  <Ul open={open}>
    

      <li>
        <NavLink  to='/gallery' className='option' activeClassName="active__option" onClick={toggleOpen}>
          Gallery
        </NavLink>
      </li>

    
      <li>
        <NavLink  to='/hardware' className='option' activeClassName="active__option" onClick={toggleOpen}>
          Hardware
        </NavLink>
      </li>
      <li>
        <NavLink  to='/design' className='option' activeClassName="active__option" onClick={toggleOpen}>
          Design
        </NavLink>
      </li>
      
      <li>
       <div className='btn-header'>
       <Link to='/contact-us' onClick={toggleOpen}> 
          <Button >
                Enquire 
          </Button>
        </Link>
       </div>
        
      </li>
      
  </Ul>
)};



export default RightHeader;
