import React  from 'react';
import './card.styles.css';

export const Card = props =>{


    return(
        
    <div className = 'card-container' style={{backgroundColor:"white"}}  >
       
       
        <img alt="img" className='image' src={props.imageUrl}/>
        <h2 className='title'> {props.title} </h2>
        <p className='para'>{props.para}</p>

    </div>
   
    );
}

export default Card