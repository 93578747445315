import React from 'react'
import Navbar from '../Navbar/Navbar'
import Button from '../custom-button/custom-button.component'
import Logo from '../../assets/LogoP.png'
import './totalheader.css'
import { Link } from 'react-router-dom'
const TotalHeader = () =>{
    return(
        <div className='header'>
        <Link to='/'>
          <div className='logo'>
          <img className='logo-img' src={Logo} alt='logo.jpg'></img>
          </div>
        </Link>
        <div className='subheader-two'>
          <div className='subheader-two-upper'>
			  <div id="path">&nbsp;</div>
             <h3 style={{margin:'10px 0', fontWeight:'500' }} > 
				 <marquee>Currently serving in Gurgaon, Noida , Delhi ,Faridabad & Ghaziabad
				 </marquee>
			 </h3>
             <h3 id='subheader-two-upper-right' style={{margin:'10px 0', fontWeight:'600', paddingRight:'15px'}}>Why ModPro? |  +91 8851998277</h3>
          </div>
           
          <div className='subheader-two-lower'>
            <Navbar />
            <div className='btn-subheader'> 
              <Link to='/contact-us'> 
                <Button >
                  Enquire 
                </Button>
              </Link>
            </div>
            
          </div>
         
        </div>

      </div> 
    )
}
export default TotalHeader
