import React from 'react';
import './terms.css'
const Terms = () =>{
    return(
        <div className='terms'>
           <div style={{paddingTop:'155px'}}><h1>Terms of Use</h1></div> 
            <h3>Acceptance of Terms</h3>
            <p>Please read these terms and conditions ('Terms') carefully. By using or accessing the Modpro  website at www.modpro.in (the 'Site') and any related mobile or other applications, you are agreeing to the terms and conditions set out below.</p>
            <h3>Eligibility to use the Product & Services</h3>
            <p>To register with and shop with www.modpro.in you must be eighteen (18) years of age or over and you are able to understand and agree to the terms, conditions, obligations, representations, and warranties set forth in these Terms. If you are a minor i.e. under the age of 18 years, you may use www.modpro.in only with the involvement of a parent or guardian.</p>
            <h3>Provision of the Services</h3>
            <ol>
                <li>You acknowledge and agree that the form and nature of the Site, may require affecting certain changes in it, therefore, we reserve the right to suspend the Site, or cancel, or discontinue any or all products or services at any time without notice, make modifications and alterations in any or all of its contents, products and services contained on the Site without any prior notice.</li>
                <li>You acknowledge and agree that if we disable access to your account, User may be prevented from accessing the Site, your account details or any files or other content, which is contained in your account.</li>
                <li>The content on this Site is provided for general information of the product and services only and should not be relied upon or used as the sole basis for making decisions without consulting primary, more accurate, more complete or more timely sources of information. Any reliance on the content or any other material on this Site is at your own risk.</li>
                <li>We reserve the right to charge subscription and/or membership fees from you, by giving reasonable prior notice, in respect of any product, service or any other aspect of the Site anytime in future.</li>
                <li>We may from time to time introduce referral and/or incentive-based programs for its users (Program). These Program(s) maybe governed by their respective terms and conditions. By participating in the Program, you are bound by the Program terms and conditions as well as these terms. We reserve the right to modify, cancel and discontinue its Program without notice to you.</li>
                

            </ol>
            <h3>Third Party Sites</h3>
            <p>As a convenience to you, we may provide, on this Site, links to web sites operated by other entities. If you use these sites, you will leave the Site. We make no warranty or representation regarding, and does not endorse, any linked Web sites or the information appearing thereon or any of the products or services described thereon.</p>
            <h3>Information Provided by You</h3>
            <p>We respect your privacy. Any personally identifiable information you may provide to Modpro via this Site is protected by the Privacy Policy associated with this Site. We do not want you to, and you should not, send any confidential or proprietary information to Modpro via the Site. Telephone calls made to and from our Customer Service team are recorded for quality and monitoring purposes. We do not sell, rent or transfer this information to third parties for their marketing purposes unless you agree otherwise. At the time of your registration you will receive a password. Please keep this secret, as you are entirely responsible to maintain the confidentiality of your password. You are responsible for all orders placed with us or information given to us under your e-mail address or mobile in combination with your password. You must immediately notify us of any unauthorized use of your e-mail address and/or password, or any breach of security known to you.</p>
            <h3>Compliance with Laws</h3>
            <p>You shall comply with all laws and regulations in India and you will use the Site in compliance with these Terms and applicable law.</p>
            <h3>Limitations of Damages</h3>
            <p>We will not be deemed to be in breach of contract or of these Terms as a result of any delay in our performance or failure to perform our obligations if that delay or failure to perform is due to any cause or circumstance beyond our reasonable control including, but not limited to, fire, flood and other acts of God, strikes, riot, accident, disruption to energy supplies, civil commotion, acts of terrorism or war, breakdown of equipment, road traffic problems.</p>
            <p>In any event whatsoever, our maximum liability arising out of any order for the supply of products to you under this contract will be limited to the retail price of the products contained in that order.</p>
            <p>Neither we nor any of our subsidiaries will be liable to any entity for any indirect, special, consequential or other damages that are related to the use of, or the inability to use, the content, materials, and functions of the site or any linked website.</p>
            <h3>Indemnity and Release</h3>
            <p>You shall indemnify and hold us harmless from any claim or demand, or actions including reasonable attorney's fees, made by any third party or penalty imposed due to or arising out of your breach of these Terms or any document incorporated by reference, or your violation of any law, rules, regulations or the rights of a third party.</p>
            <h3>Changes</h3>
            <p>We reserve the right, to change, modify, add or remove any portion of these Terms. Changes in these Terms will be effective when notice of such change is posted. Your continued use of the Site after any changes to these Terms are posted, will be considered acceptance of those changes.</p>
            <h3>Intellectual Property</h3>
            <p>Subject to the terms and conditions set forth in these Terms, we authorize you to view and download the information at this Site ('Hardware/Kitchens') only for your personal, non-commercial use and you must retain on all Materials, all copyright and other proprietary notices. You shall not modify the Materials in any way or reproduce it. All images shown here have been digitized by us. No other party is authorized to reproduce or republish these digital versions in any format whatsoever without our prior written permission. All copyright, trademarks and all other intellectual property rights in all material or content supplied as part of this Site shall remain at all times vested in us.</p>
            <h3>Governing Law & Jurisdiction</h3>
            <p>These Terms, are governed by and construed in accordance with the laws of India. You agree, as we do, to submit to the exclusive jurisdiction of the courts at Mumbai.</p>
            <p>Any cause of action you may have with respect to your use of the Site must be commenced within one (1) year after the claim or cause of action arises.</p>
            <h3>ORDER PLACEMENT AND PRICING TERMS</h3>
            <h4>1. Order</h4>
            <p>1.1 All use and purchases made on this Site are governed by these Terms. Although the Terms governing any given use or purchase will be those in effect at the date of your order or specific use. If you use or order products after we have published any changes you will be bound by those changes. Accordingly, you should check prior to each use or order to ensure that you understand the precise Terms applicable to your Site visit or purchase. To assist you in determining whether the Terms have changed since your most recent order we will display the date when these Terms were most recently updated.</p>
            <p>1.2 We sell products to the Interior firm, Architecture Firm and Builder and Constructor firm.</p>
            <p>1.3 We will confirm your order whether placed directly by using the Site by message on the Site immediately after you have confirmed your order and by sending an e-mail to the e-mail address you have provided to us or mobile number you have provided to us.</p>
            <p>1.4 Please note that the order confirmation does not constitute our acceptance of your order. Subject to our cancellation rights, acceptance of your order and completion of the order will take place when we dispatch the products to you.</p>
            <p>1.5 Before placing an order, you are advised to check the product description carefully. By placing an order for a product, you agree to be bound by the conditions of sale included in the item's description.</p>
            <h4>2. Product Pricing</h4>
            <p>2.1 The prices stated on the Site will be exclusive of any GST payable.</p>
            <p>2.2 Each order you make may include a charge for delivery. If your delivery is subject to a delivery charge, it will be shown to you as a separate charge on the delivery confirmation page before you confirm your order. Any delivery charge added to your order will be dependent upon, amongst other things, the value of your order, the date and time of your delivery and any additional options you may pick.</p>
            <h4>3. Payment Options</h4>
            <p>3.1 Payment may be made by debit card, credit card, internet banking and some wallets, cash and cheque. </p>
            <p>3.2 The debit, credit and charge cards accepted by us are those listed on the Site on the date on which your order is placed.</p>
            <p>3.3 EMI or No-cost EMI option selected by you may involve some processing and interest charges. This will be a formal contract between you and the relevant service provider and is entered into at your sole discretion and we are a mere facilitator and not party to such contract.</p>
            <h4>4. Shipment of Products:</h4>
            <p>4.1 Delivery days and times will be as specified on the cart page on the Site and will be made to the address specified by you when you place an order on the Site. We reserve the right to restrict deliveries in certain areas, and this includes the right to eliminate certain areas from our delivery schedule altogether.</p>
            <p>4.2 Products are subject to availability and prevailing market conditions. We may limit the quantities of products ( particularly products on special offer) supplied to any one customer if in our opinion the quantity ordered jeopardizes availability for other customers.</p>
            <p>4.3 We will always try to supply you with the full quantity and accurate product that you have ordered. In any event, subject to clause 7.1 below, our liability will be limited to the price of the products not delivered or incorrectly delivered and the cost of delivery.</p>
            <p>4.4 We take special care to endeavor that deliveries are made on time and accordingly, it is your responsibility to ensure that an appropriate person is available at the delivery address during the delivery day. We may ask that an appropriate person signs for the products on delivery.</p>
            <p>4.5 Please note adverse weather conditions or other events outside of our reasonable control may result in the occasional late delivery. If that is the case, we will endeavor to contact you as soon as we are able to reschedule your delivery time and date. In any event, our liability to you will be limited to a return of the price of products not delivered and the cost of delivery.</p>
            <p>4.6 Should you fail to be present for your delivery ( or in cases where the wrong address is provided) we are entitled to charge you in full for an amount equal to the price of product and the cost of delivery.</p>
            <p>4.7 The confirmation e-mail you receive from us confirming your order, will set out the last time that you may amend your order prior to delivery.</p>
            <p>4.8 You can change the address you have registered on the Site through the 'Address Change' feature. This will not change the address for any orders where you have received our confirmation email.</p>
            <h4>5. Out of Stock Situations</h4>
            <p>Our endeavour is to ensure that all products listed on the Site are available in stock for dispatch to you. However, in case this in not available for any reason, we will contact you and give you the option to exchange, delay or cancel the order based on your convenience.</p>
            <h4>6. Product returns</h4>
            <p>6.1 We advise that you inspect the products promptly at the time of delivery and installation and notify us of any defects promptly. If you discover a product is faulty, please call our Customer Service team as soon as possible on 8851998277 or email us at info@modpro.com for best possible action to resolve the issue.</p>
            <p>6.2 To return any items that are damaged or not as per your original order, please return at the time of delivery to the delivery person or inform our customer care within 24 hours from the time of the product delivery. Please check the terms and conditions of each product you order to confirm its cancellation policy.</p>
            <p>6.3 All damaged items or items not as per your order that are to be returned must be accompanied by a copy of the original receipt and in the same condition at the time of delivery, otherwise the same will not be taken back.</p>
            <p>6.4 Please note that we cannot offer exchanges or refunds on any opened or used products.</p>
            <p>6.5 Please allow one to three weeks from the day you return your damaged package or an item not as per your order, for your refund request to be processed. You will be contacted once your return is complete.</p>
            <h4>7. Customer Complaints</h4>
            <p>7.1 Any customer complaints should be addressed to the Customer Service Helpline 8851998277 or email us at info@modpro.com operational between on days. You will find an e-mail link or address and telephone numbers listed on our Site in the 'Contact' section.</p>
            <h4>8. Severability</h4>
            <p>If any part of the provisions contained in these Terms are determined to be invalid, or unenforceable to any extent, such provision shall be severed from the remaining provisions which shall continue to be valid and enforceable to the fullest extent permitted by law.</p>
            <h4>9. Submissions</h4>
            <p>We welcome your comments about your experiences shopping with us and your suggestions about how to improve this site. You control all the rights to the content that you contribute to this Site and that use of your content by us will not infringe upon or violate the rights of any third party.</p>
            <h4>10. Disclaimer</h4>
            <p>The prices and products advertised on this Site are for sale from this Site only. Prices and product availability may vary between Modpro online site and retail store. As prices, product descriptions and availability can change quickly; Modpro does not warrant the accuracy or completeness of information on this Site and will not be responsible for any typographical errors or errors in illustrations, pictures or descriptions. Pictures of products are for illustration only and actual product may differ from the picture shown. Products represented on this Site are not always in stock. All item descriptions, images, product logos, availability and pricing are subject to change at any time without notice. We may also make any other changes to the site, the materials and the products, programs, services or prices described in the site at any time. This Site, the information and materials on the Site, are provided 'as is' without any representation or warranty, express or implied, of any kind, including, but not limited to, warranties of merchantability, non-infringement, or fitness for any particular purpose.</p>


        </div>
    );
}
export default Terms;