import React from 'react';
import './brands.css' 
import HettichSponsor from '../../assets/hettichSponsor.jpg'
import HafeleSponsor from '../../assets/hafeleSponsor.png'
import ActionTessaSponsor from '../../assets/actionTessaSponsor.jpg'
import MerinoSponsor from '../../assets/merinoSponsor.jpg'
import GreenlamSponsor from '../../assets/greenlamSponsor.jpg'
import RehauSponsor from '../../assets/rehauSponsor.jpeg'


const Brands =() =>{
    return(
        <div className='sponsors-page'> 
             <div className='t-3' style={{display:'flex' , alignItems:'center'}}>
                <hr className='h-r3' ></hr>
                <h1 className='associated-brands' >Associated <span style={{color:'orange'}}>Brands</span></h1>
            </div>
            <div className='sponsors'>
                <div className='sponsor'>  <img  src={HettichSponsor} alt='sponsor.jpg'></img></div>
                <div className='sponsor'>  <img  src={MerinoSponsor} alt='sponsor.jpg'></img></div>
                <div className='sponsor'>  <img  src={ActionTessaSponsor} alt='sponsor.jpg'></img></div>
                <div className='sponsor'>  <img  src={RehauSponsor} alt='sponsor.jpg'></img></div>

                <div className='sponsor'>  <img  src={HafeleSponsor} alt='sponsor.jpg'></img></div>
                <div className='sponsor'>  <img  src={GreenlamSponsor} alt='sponsor.jpg'></img></div>

             </div>
        </div>
        
    );
}
export default Brands;