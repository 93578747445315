

import React from 'react';
import './card-list.styles.css';
import CardGallery from '../Card-Gallery/card.component';
import DATA from '../../data';
 const CardList = () => {
   
return <div className ='card-list'>

{DATA.map(monster => (<CardGallery key={monster.id} monster= {monster} /> ) )}

</div>;
};
export default CardList

// map demo

// arrOfBooks=[{name:"physics"} , {name:'chem'}]

// return(
// {arrOfBooks.map(book=> ( <h1>{book.name}</h1>   )   )}   // physics
//                                                             //chem
// )