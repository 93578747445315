import React from 'react';
import  Card  from '../Card/card.component';
import './hpdiv6.css'
import div6img1 from '../../assets/div6img1.png'
import div6img2 from '../../assets/div6img2.png'
import div6img3 from '../../assets/div6img3.png'
import div6img4 from '../../assets/div6img4.png'

const Hpdivsix =() =>{
    return(
        <div className='hpdiv6'>
            <Card title='Upto 5-Years Warranty' para='Choose modular woodwork done with superior quality material, leaving no room for defects ' imageUrl={div6img1} />
            <Card title='28-day Delivery' para="Get modular woodwork for your client in just 28 days. That’s our delivery guarantee." imageUrl={div6img2}/>
            <Card title='Experts for Quality Check' para=' Quality check at each step of production & installation to make sure quality assured delivery ' imageUrl={div6img3}/>
            <Card title='Post Installation Service' para='The one & only modular woodwork solution provider value importance of post installation service' imageUrl={div6img4}/>
        </div>
    );
}
export default Hpdivsix
