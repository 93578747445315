import React from 'react';
import './aboutdiv3.css'
const Aboutdivthree =()=>{
    return(
        <div className='about-div3'>
            <div className='about-div3-left'>
                   <p>Quality check at produciton & installation stage missing </p>
                    <p>Time for production taking more than 60 days after drawing confirmation </p>
                    <p> Work at site for installation taking more than expected time </p>
                    <p>Lack of warranty & post installation support </p>
            </div>

         
        </div>
    );
}
export default Aboutdivthree
/*   <div className='about-div3-right'>
                <h2>Partner with <span style={{color:'orange'}}>Modpro</span> for Growth</h2>
                <hr style={{ border: "1px solid orange" , width:"134px" , height:"0px" , marginRight:'-10vw'}}></hr>
            </div> 
*/
