import React from 'react';
import './about-div2.css'
const Aboutdivtwo =()=>{
    return(
        <div className='about-div2'>
            <div className='about-div2-left'>
                <hr style={{ border: "1px solid orange" , width:"134px" , height:"0px" ,marginLeft:'-130px'}}></hr>
                <h2>Are you facing these problems?</h2>
            </div>

            <div className='about-div2-right'>
             
                    <p>Facing difficulties in providing fully modular kitchens , wardrobes & Storages .</p>
                    <p>Confidence in Modular Measurements & Site Mapping </p>
                    <p>Converting measurements into modular specficitations as per standard</p>
                    <p>Lacking Modular Installation Skills/Resources </p>
               
            </div>

        </div>
    );
}
export default Aboutdivtwo
