import React  from 'react';
import { Link } from 'react-router-dom';
import './card.styles.css';

 const CardGallery = props =>{


    return(
        <Link to={props.monster.routeName}>
    <div className = 'card-container-gallery'  >
        <img alt="img" className='product-image' src={props.monster.imageUrl}/>
    </div>
    </Link>
    );
}
export default CardGallery;
