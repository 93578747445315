import './App.css';
import { Switch , Route } from 'react-router-dom';
import TotalHeader from './components/TotalHeader/totalheader';
import Home from './containers/Homepage/homepage.component';
import Footer from './components/Footer/footer';
import About from './containers/About-us/about-us';
import PrivacyPolicy from './containers/Privacy-policy/privacy-policy';
import Terms from './containers/TermsAndConditions/terms';
import Contact from './components/Contact/contact';
import Faqs from './components/FAQS/faqs';
import GalleryPage from './containers/GalleryPage/gallery';
import Design from './containers/Design/design';
import Hardware from './containers/Hardware/hardware';
import WhatsAppIcon from './assets/WhatsAppIcon.png'
function App() {
  var viewportwidth= window.screen.width;
  var whatsapplink='https://wa.me/+9188519 98277'
  if(viewportwidth<=600){whatsapplink='https://wa.me/+918851998277'}
  else{whatsapplink='https://web.whatsapp.com/send?phone=918851998277&text='}
  return (
    <div className="App">
      
      <TotalHeader />
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/about-us' component={About} />
        <Route exact path='/privacy-policy' component={PrivacyPolicy} />
        <Route exact path='/termsofuse' component={Terms} />
        <Route exact path='/contact-us' component={Contact} />
        <Route exact path='/faqs' component={Faqs} />
        <Route path='/gallery' component={GalleryPage} />
        <Route exact path='/design' component={Design} />
        <Route exact path='/hardware' component={Hardware} />


      </Switch>
      
      <Footer />
      <a href={whatsapplink} target='blank'><img href='' className='whatsapp-my-float whatsapp-float' src={WhatsAppIcon}></img></a>
     
    </div>
  );
}

export default App;
