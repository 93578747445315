import React from 'react';
import './about-us.css'
import Aboutdivtwo from '../../components/about-div2/about-div2';
import Aboutdivthree from '../../components/about-div3/about-div3';
const About =() =>{
    return(
        <div className='about-us'>
            <div className='text-above-about'>
            <h1 style={{textAlign:'center'}}>About <span style={{color:'orange'}} >ModPro</span></h1>
            <p className='top-p' >Modpro is the modular woodwork solution provider with the help of experienced professionals
            to give you factory finished products with-in defined quality & timelines &</p>
            </div>
           

            <div className='table'>
               <div className='row-about'>
                    <div className='rect-about left'><h4>Managed by Top Corporate Executives</h4></div>
                    <div className='circle-about'></div>
                    <div className='circle-about'></div>
                    <div className='rect-about right'><h4>Brand you can trust upon</h4></div>
               </div> 
                    <div className='row-about'>
                        <div className='rect-about left'><h4>Fully customized yet Modular solution</h4></div>
                        <div className='circle-about'></div>
                        <div className='circle-about'></div>
                        <div className='rect-about right'><h4>Quality check at each step</h4></div>
                    </div> 
               <div className='row-about'>
                        <div className='rect-about left'><h4>End to End Modular Solution Provider</h4></div>
                        <div className='circle-about'></div>
                        <div className='circle-about'></div>
                        <div className='rect-about right'><h4>Highly scalable production architecture</h4></div>
               </div> 
                    <div className='row-about left'>
                        <div className='rect-about left'><h4>Authorised Partner with Leading Hardware Brands</h4></div>
                        <div className='circle-about'></div>
                        <div className='circle-about'></div>
                        <div className='rect-about right'><h4>On Time Delivery , Giving you Peace of Mind </h4></div>

                    </div> 
            </div>
            <Aboutdivtwo />
            <Aboutdivthree />
       
        </div>

    );
}
export default About
