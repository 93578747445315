import React from 'react';

import Howitworks from '../../assets/howitworks.png'
import Howitworks1 from '../../assets/howitworks-mobile.png'
import './howitworks.css'
const HowItWorks =( ) =>{
    return(
        <div className='howitworks'>
            <div className='t-2' style={{display:'flex' , alignItems:'center'}}>
                <hr className='h-r2' ></hr>
                <h1 className='how-it-works' >How It <span style={{color:'orange'}}>Works?</span></h1>
            </div>
            <img src={Howitworks} id="img-hw" alt='howitworks.png'></img>
			<img src={Howitworks1} id="img-hw1" alt='howitworks.png'></img>
        </div>
    )
}
export default HowItWorks