import React from 'react';
import { Route  } from 'react-router-dom';
import CardDetail from '../../components/Card Details/cardDetails';
import CardList from '../../components/Card-List/Card-list.component';

const GalleryPage = ({ match }) => {
  // console.log(match.path)
  return(
  <div >
  
    <Route exact path={`${match.path}`} component={CardList} />
    <Route  path={`${match.path}/:id`} component={CardDetail} />
   
   
  </div>
);
}
export default GalleryPage;