import React from 'react';
import Button from '../custom-button/custom-button.component';
import './hpdiv3.css'
import Subtraction from '../../assets/Subtraction.png'

const Hpdivthree =()=>{
    return(
        <div className='hpdiv3'>
            <div className='hpdiv3-left'>
                <h2 style={{alignSelf:'center', fontWeight:'700', textTransform:'uppercase'}} >We At <span style={{color:'orange', textTransform:'none'}}>ModPro</span> </h2>
                <p>ModPro is a Brand focussing on modualarization of woodwork. It was founded with the aim to provide the
        best modular woodwork solutions to the interior & construction organizations so that they can focus on their best and we can take care of rest.We are India’s 1st
        Modular wholesale brand that provides orders as per commited with assured quality check
        at various manufacturing and delivery stages.</p>
                <p>We are living in fast paced environment , where time is the money. Given the challenge with available work hours at site for any woodwork , it is always best to get production outside in controlled environment where quality can be managed and inspected by professionals , yet delivery done with-in defined SLAs.So we are here to work for you , growing your business further </p>
            </div>
            <div className='hpdiv3-right' style={{zIndex:'-1' , position:'relative'}}>
                <img src={Subtraction}></img>

            </div>

           
        </div>
    );
}
export default Hpdivthree
