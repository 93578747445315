import React from 'react'
import "./cardDetails.css"
import { useParams } from "react-router";
import DATA from '../../data';

function getCollection(id) {
  
  return DATA[id-1];
}

const CardDetail = () =>{
     const { id } = useParams();
   const collection =getCollection(id);
    const {imageUrl } =collection;
    return(
		<div className='cards' >
    <div className='card-cardDetail'>     
        <img className='image-card-detail' src={imageUrl} alt="om" />
    </div>
			</div>

    );
}


  
  export default CardDetail;