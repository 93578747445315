import React from 'react';
import div7img1 from '../../assets/div7img1.png'
import div7img2 from '../../assets/div7img2.png'

import './hpdiv7.css'
const Hpdivseven =() =>{
    return(
        <div className='hpdiv7'>
           <div><h2 style={{textAlign:'center', fontWeight:'700', textTransform:'uppercase',fontSize:'30px'}}>Testimonials</h2></div> 
           <div className='content'>
               <img src={div7img1}></img>
               <div className='dp'></div>
               <div className='review'>
                    <p>When we first met the ModPro team we knew we had found what we needed: a group of
        young, enthusiastic, sensitive professionals who understand the modular world and helping us deliver the quality solutions in defined time with lot of variety & scalable architecture . We had earlier explored all sort of option of getting carpenter job at site till semi modular factory setup run by highly unorganized  people and struggled most of time delivering right solution to our end client but with ModPro , look like we got our growth partner.</p>

			<p style={{float:"left", textTransform:'uppercase', fontWeight:'700'}}>
				<img id="ratings" src={div7img2}></img>
				- Umbrette
				   </p>
           		

               </div> 
           </div>
        </div>
    );
}
export default Hpdivseven
