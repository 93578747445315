import React from 'react';
import div2img1 from '../../assets/div2img1.png'
import div2img2 from '../../assets/div2img2.png'
import div2img3 from '../../assets/div2img3.png'
import div2img4 from '../../assets/div2img4.png'
import div2img5 from '../../assets/div2img5.jpg'


import './hpdiv2.css'


const Hpdivtwo =( ) =>{
    return(
        <div className='hpdivtwo'>
            <div className='hpdivtwo-left'>
                <div className='t-1' style={{display:'flex' , alignItems:'center'}}>
                <hr className='h-r' ></hr>
                <div className='why-modpro'><h1 style={{textAlign:'center',fontWeight:'700', textTransform:'uppercase'}}>Why <span style={{color:'orange', textTransform:'uppercase'}}>Modpro?</span> </h1></div>
                </div>
                
                <div className='hpdivtwo-left-para'  ><p >Grow your business with modular professionals who understands the complete ecosystem & help you scale </p></div>
                <div className='image-section'>
                    <div className='imgCont'> <img src={div2img1} /> <p>Quality</p></div>
                    <div className='imgCont'> <img src={div2img2} /><p>Scalability</p></div>
                    <div className='imgCont'> <img src={div2img3} /><p>Variety</p></div>
                    <div className='imgCont'> <img src={div2img4} /><p>Reliability</p></div>

                </div>

            </div>
            <div className='hpdivtwo-right'>
                <img src={div2img5} />

            </div>
        </div>
    );
}
export default Hpdivtwo
