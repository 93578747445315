import React from 'react';
import './comingsoon.css'
import UnderConstruction from '../../assets/Underconstruction.mp4'
const ComingSoon = () =>{
    return(
        <div className='comingsoon'>
           <video  autoPlay muted className='video-landing2'>
				        <source src={UnderConstruction} alt={UnderConstruction} type='video/mp4' />
			        </video>
            
        </div>
    );
}
export default ComingSoon