import React from 'react'
import { Link } from 'react-router-dom';
import './hpdiv1.css'
import Button from '../custom-button/custom-button.component'
import video from '../../assets/video.mp4'
const Hpdivone = () =>{
    return(
        <div className='hpdivone'>

                    <div className='texts-div1'>
                        
                        <div className='partner'>

                            <h2 id='h2-partner-1' >Partner for <span id='span-partner-1' >Growth</span> </h2>
                            <h2 id='h2-partner-2' >with <span id='span-partner-2' >ModPro</span> </h2>
                            <div id='get-qt-bt' style={{opacity:'1', position:'relative'}}> <Link to='./contact-us'> <Button green id='quot-button'>Get Quotation</Button></Link></div>
                        </div>
                        
                       
                       
                    </div>
                    <video loop autoPlay muted className='video-landing'>
				        <source src={video} alt={video} type='video/mp4' />
			        </video>
               
        </div>
    );
}
export default Hpdivone